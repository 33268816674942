import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from 'gatsby-plugin-image'
import MiniLawnChair from '../../images/miniLawnChair.svg'

const Hardscaping = () => {

	const TheTitle = "Hardscape and Hardscaping services"
	const TheDescription = "Hardscape refers to the use of hard materials to create surfaces, walls and features on your property. These are most commonly, but not limited to patios, retaining walls, stairs or pathways. They can be used to create decorative spaces or surfaces or to reshape your property to make it more practical, presentable or durable."
	const TheCanonical = "https://gardenescape.ca/services/hardscaping/"

	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			<div className="serviceGrid">
				<StaticImage
					className="serviceGrid_boxA"
					src="../../images/home/leaves.png"
					alt="background placeholder"
					layout="fullWidth" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>
			<div className="mainWrapper my-16">
			<h2>What is Hardscape?</h2>
				<p>Hardscape refers to the use of hard materials to create surfaces, walls and features on your property.  These are most commonly, but not limited to; patios, retaining walls, stairs or pathways. They can be used to  create decorative spaces or surfaces or to reshape your property to make it more practical, presentable or  durable. Typically hardscape projects utilise materials like; stone, concrete, brick, wood, tile and sometimes even  metal.</p>
				<h2>Why install Hardscape?</h2>
				<p>Hardscaping can add incredible beauty and practicality to a home. If you just want to convert a dirt path  to  redbrick to reduce dirt being tracked around your property. Or you want to level off large sections of  your  sloped yard with a massive stone retaining wall to create a yard game playing area. Hardscape will add  tremendous value. When installed properly hardscaped areas can last for decades with very little  maintenance.</p>
				<h2>HOW DO I PROGRESS?</h2>
				<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan  that’ll best  fit your yard goals and budget. Just contact us through our website, phone, email or social media.</p>
				<h2>OTHER WAYS WE CAN SERVE YOU.</h2>
				<p>We are happy to help you with all things related to beautifying your property, some other major services  we offer  are; Dethatching, fertilising, Hedge and Shrub pruning, Aeration and Junk Removal.</p>
			</div>
		</Layout>
	)
}

export default Hardscaping